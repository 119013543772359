import { fetchAsObservable } from "fetcher!sofe";
import { TInvite } from "src/client-portal-invite-modal/types";

export function sendPortalInviteObs({
  clientId,
  invites,
  message,
}: {
  clientId: string;
  invites: TInvite[];
  message?: string;
}) {
  const body: { invites: TInvite[]; message?: string } = { invites };
  if (message) body.message = message;

  return fetchAsObservable(`api/v2/clients/${clientId}:portal_invite`, {
    method: "POST",
    body,
  });
}

export function sendPortalInvite({
  clientId,
  invites,
  message,
}: {
  clientId: string;
  invites: TInvite[];
  message?: string;
}) {
  return new Promise((resolve, reject) =>
    sendPortalInviteObs({ clientId, invites, message }).subscribe(resolve, reject)
  );
}

export function resendPortalInviteObs(contactId: string) {
  return fetchAsObservable(`api/v2/contacts/${contactId}:resend_portal_invite`, {
    method: "POST",
  });
}

export function removePortalAccess({ contactId, clientIds }: { contactId: string; clientIds: string[] }) {
  return fetchAsObservable(`/api/v2/contacts/${contactId}:remove_portal_invite`, {
    method: "PATCH",
    body: {
      client_ids: clientIds,
    },
  });
}

export function unlinkClientPortalLoginObs(contactId: string) {
  return fetchAsObservable(`/api/v2/contacts/${contactId}:unlink`, {
    method: "PATCH",
  });
}

export function checkEmailAvailabilityObs(email: string) {
  return fetchAsObservable(`api/v2/contacts:email-availability?email=${email}`);
}

export function checkEmailAvailability(email: string) {
  return new Promise<{
    available: boolean;
  }>((resolve, reject) => checkEmailAvailabilityObs(email).subscribe(resolve, reject));
}

export function checkEmailAvailabilityByContactIdsObs(contactIds: string[]) {
  return fetchAsObservable(`api/v2/contacts:email-availability`, {
    method: "POST",
    body: {
      contact_ids: contactIds,
    },
  });
}

export function checkEmailAvailabilityByContactIds(contactIds: string[]) {
  return new Promise<{
    available: {
      id: string;
      email: string;
    }[];
    taken: {
      id: string;
      email: string;
      taken_by?: {
        id: string;
        name: string;
        role?: string;
      };
    }[];
  }>((resolve, reject) => checkEmailAvailabilityByContactIdsObs(contactIds).subscribe(resolve, reject));
}
