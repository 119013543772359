import { TContact } from "src/common/types";
import { handleError } from "src/error";
import { contactQueries } from "src/queries";
import { resendPortalInviteObs } from "src/resources/client-portal-access.resource";
import { updateContact, deleteContact as deleteContactPromise } from "src/resources/contacts.resource";
import { successToast } from "toast-service!sofe";

export async function unarchiveContact(contact: TContact) {
  try {
    await updateContact(contact.id, { is_archived: false });
    successToast(`Successfully unarchived ${contact.name}.`);
    contactQueries.invalidate();
  } catch (e) {
    handleError(e);
  }
}

export async function deleteContact(contact: TContact) {
  try {
    await deleteContactPromise(contact.id);
    successToast(`Contact successfully deleted.`);
    contactQueries.invalidate();
  } catch (e) {
    handleError(e);
  }
}

export function resendPortalInvite(contact: TContact) {
  resendPortalInviteObs(contact.id).subscribe(() => {
    successToast(`Successfully resent invitation to ${contact.name}`);
    contactQueries.invalidate();
  }, handleError);
}
