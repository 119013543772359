import React from "react";
import { CprLoader } from "canopy-styleguide!sofe";

export default function ClientCardLoader(props) {
  return (
    <div className="cps-margin-top-32">
      <CprLoader dotSize={16} />
    </div>
  );
}
