import React, { useState } from "react";
import { CpCheckbox, CpButton, CpModal, CpWell } from "canopy-styleguide!sofe";
import { successToast } from "toast-service!sofe";
import { TAssignedClient, TContact } from "src/common/types";
import { removePortalAccess } from "src/resources/client-portal-access.resource";
import { handleError } from "src/error";

type RemovePortalAccessModalProps = {
  contact: TContact;
  onAfterClose: () => void;
};

export function RemovePortalAccessModal({ contact, onAfterClose }: RemovePortalAccessModalProps) {
  const [show, setShow] = useState(true);
  const [checkedIds, setCheckedIds] = useState<string[]>([]);

  function close() {
    setShow(false);
  }

  function onCheckboxChange(id: string, isChecked: boolean) {
    if (isChecked) {
      setCheckedIds((prevIds) => [...prevIds, id]);
    } else {
      setCheckedIds((prevIds) => prevIds.filter((existingId) => existingId !== id));
    }
  }

  function onRemoveAccess() {
    removePortalAccess({ contactId: contact.id, clientIds: checkedIds }).subscribe(() => {
      close();
      successToast("Successfully removed access for " + contact.name);
    }, handleError);
  }

  return (
    <CpModal show={show} onClose={close} onAfterClose={onAfterClose}>
      <CpModal.Header title="Remove access" />
      <CpModal.Body>
        {`Are you sure you want to remove access from ${contact.name}? By removing access they will no longer be able to log into the client portal.`}
        <CpWell className="cp-p-16 cp-mt-16 flex flex-col cp-gap-16">
          {contact.clients
            ?.filter((assignedClient: TAssignedClient) => !!assignedClient.user_invited_at)
            ?.map((assignedClient: TAssignedClient) => {
              const name = assignedClient.display_name || assignedClient.name;
              return (
                <div className="flex justify-between" key={assignedClient.id}>
                  <label className="grow" htmlFor={name}>
                    {name}
                  </label>
                  <CpCheckbox
                    id={name}
                    checked={checkedIds.includes(assignedClient.id)}
                    onChange={(checked: boolean) => onCheckboxChange(assignedClient.id, checked)}
                  />
                </div>
              );
            })}
        </CpWell>
      </CpModal.Body>
      <CpModal.Footer>
        <div className="flex cp-gap-8">
          <CpButton btnType="primary" onClick={onRemoveAccess} disabled={checkedIds.length === 0}>
            Remove access
          </CpButton>
          <CpButton btnType="flat" onClick={close}>
            Cancel
          </CpButton>
        </div>
      </CpModal.Footer>
    </CpModal>
  );
}
