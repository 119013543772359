import React, { ForwardedRef, forwardRef } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { cssModules, useCssModules } from "inline-css-modules-react";
import { CpCheckboxField, CpIcon, CpInputField, CpTooltip } from "canopy-styleguide!sofe";
import { useHasAccess } from "cp-client-auth!sofe";
import FieldSection from "src/common/field-section.component";
import {
  ActiveClientToggle,
  ClientOwnerInput,
  ClientTypePicker,
  FilingStatusSelect,
} from "src/common/form/client/base";
import { FieldLabel } from "src/common/form/field-label.component";
import { SourceDropdown } from "src/create-edit-client-modal/source-dropdown.component";
import { DateInput } from "src/form/date-input/date-input.component";
import { ClientNameField } from "src/common/form/client/client-name-field.component";

interface ClientInfoSectionProps {
  isBusiness: boolean;
  initialDisplayName?: string;
}

export const ClientInfoSection = forwardRef(
  ({ isBusiness, initialDisplayName }: ClientInfoSectionProps, ref: ForwardedRef<any>) => {
    useCssModules(css);
    const { control, watch, setValue, getValues } = useFormContext();
    const showExternalDisplayName = watch("use_external_name");
    const canSetActive = useHasAccess("clients_toggle_active");

    return (
      <FieldSection label="Client Info" ref={ref}>
        <ClientNameField
          control={control}
          fieldName="name"
          initialName={initialDisplayName}
          onBlur={() => {
            if (isBusiness && !getValues("business_name")) {
              setValue("business_name", getValues("name"));
            }
          }}
        />
        <div className="cp-flex">
          <CpCheckboxField control={control} fieldName="use_external_name">
            Use a different client-facing name
            <CpTooltip text="The client-facing name will show up on any client facing item e.g., client portal, invoices, etc.">
              <CpIcon name="information-circle-open-small" />
            </CpTooltip>
          </CpCheckboxField>
        </div>
        {showExternalDisplayName && (
          <CpInputField control={control} fieldName="external_display_name" label="Client-facing name" required />
        )}
        <div className={s.grid}>
          <Controller
            name="client_owner"
            control={control}
            render={({ field: { onChange, value } }) => <ClientOwnerInput onChange={onChange} value={value} />}
          />
          <CpInputField control={control} fieldName="external_id" label="External ID" maxLength={150} />
          {!isBusiness && (
            <Controller
              name="filing_status"
              shouldUnregister
              control={control}
              render={({ field: { onChange, value } }) => <FilingStatusSelect onChange={onChange} value={value} />}
            />
          )}
          <SourceDropdown control={control} />
          <Controller
            name="client_since"
            control={control}
            render={({ field: { onChange, value } }) => (
              <DateInput
                name="client_since"
                updateValue={onChange}
                value={value}
                label={
                  <FieldLabel>
                    <span>Client Since</span>
                  </FieldLabel>
                }
              />
            )}
          />
        </div>

        <Controller
          name="client_type"
          control={control}
          render={({ field: { onChange, value } }) => <ClientTypePicker onChange={onChange} value={value} />}
        />
        <Controller
          name="is_active"
          control={control}
          render={({ field: { onChange, value } }) => (
            <ActiveClientToggle onChange={onChange} value={value} disabled={!canSetActive} />
          )}
        />
      </FieldSection>
    );
  }
);

const { css, s } = cssModules`
  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.6rem;
  }
`;
